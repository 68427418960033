<template>
  <a-row :gutter="25">
    <div class="card-top w-100">
      <a-col :xxl="7" :lg="10" :md="12" :xs="23" style="padding-left: 0" class="box-event">
        <h3 class="form-font title">Información general</h3>
      </a-col>
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            name="sDash_validation-form"
            ref="formRef"
            :model="generalForm"
            :rules="rules"
            :layout="formState.layout"
          >
            <a-row :gutter="30">
              <a-col :md="24" :xs="24">
                <a-form-item ref="title" name="title" label="Nombre del evento *" class="form-font mx-3">
                  <a-input v-model:value="generalForm.title" placeholder="" class="input-field" @change="sendTitle($event)"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24">
                <a-form-item ref="type" name="type" label="Tipo de evento *" class="title-normal">
                  <a-select
                    size="large"
                    name="type"
                    class="sDash_fullwidth-select title-normal event-text"
                    v-model:value="generalForm.type"
                    v-on:change="handleChangeTypeEvent($event)">
                    <a-select-option class="title-normal event-text" value="">Seleccione una opción</a-select-option>
                    <a-select-option class="title-normal event-text" key="normal" value="normal">
                      Evento
                    </a-select-option>
                    <a-select-option class="title-normal event-text" key="benefits" value="benefits">
                      Beneficios
                    </a-select-option>
                    <a-select-option class="title-normal event-text" key="other" value="other">
                      Otros
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="11" :sm="24" :xs="24"
                     v-if="generalForm.type == 'normal'"
              >
                <p class="title-normal m-0">¿El evento general tendrá varios eventos agrupados? *</p>
                <a-form-item
                  ref="grouped_events"
                  name="grouped_events"
                  class="form-font pt-0 mx-3">
                  <a-radio-group v-model:value="generalForm.grouped_events" >
                    <a-radio class="form-font" :value="1"> Si </a-radio>
                    <a-radio class="form-font" :value="0"> No </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24"  v-if="generalForm.type == 'normal'">
                <a-form-item ref="amount_participants" label="Cantidad máxima de participantes" name="amount_participants" class="form-font">
                  <a-input type="number" placeholder="" v-model:value="generalForm.amount_participants" class="input-field" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
                <a-form-item label="Fecha del registro del evento" name="registration_date" class="form-font">
                  <a-input disabled placeholder="" :value="generalForm.registration_date" class="input-field" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
                <a-form-item ref="event_date" label="Fecha de inicio del evento *" name="event_date" class="form-font">
                  <a-input
                    :min='generalForm.registartion_date_formated'
                    placeholder=""
                    type="date"
                    v-model:value="generalForm.event_date"
                    class="input-field"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" v-if="generalForm.type == 'normal'">
                <a-form-item ref="end_date" label="Fecha de finalización del evento *" name="end_date" class="form-font">
                  <a-input
                    :min='generalForm.event_date'
                    placeholder=""
                    type="date"
                    v-model:value="generalForm.end_date"
                    class="input-field"
                    :disabled="generalForm.event_date == ''"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
                <a-form-item ref="event_hour" label="Hora de inicio del evento *" name="event_hour" class="form-font">
                  <a-time-picker :defaultValue="event_hour_default" format="HH:mm" @change="(time, timeString) => { generalForm.event_hour = timeString; }" placeholder="Seleccionar hora" class="input-field" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" v-if="generalForm.type == 'normal'">
                <a-form-item ref="end_time" label="Hora de finalización del evento *" name="end_time" class="form-font">
                  <a-time-picker :defaultValue="end_time_default" format="HH:mm" @change="(time, timeString) => { generalForm.end_time = timeString; }" placeholder="Seleccionar hora" class="input-field" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" v-if="generalForm.type != 'other'">
                <a-form-item ref="start_register_date" label="Fecha de inicio del registro *" name="start_register_date" class="form-font">
                  <a-input
                    :disabled="!generalForm.event_date"
                    :min='today'
                    :max='generalForm.event_date'
                    placeholder=""
                    type="date"
                    v-model:value="generalForm.start_register_date"
                    class="input-field" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" v-if="generalForm.type != 'other'">
                <a-form-item ref="end_register_date" label="Fecha de finalización del registro *" name="end_register_date" class="form-font">
                  <a-input
                    :disabled="!generalForm.event_date"
                    :min='generalForm.start_register_date'
                    :max='generalForm.event_date'
                    placeholder=""
                    type="date"
                    v-model:value="generalForm.end_register_date"
                    class="input-field" />

                </a-form-item>
              </a-col>
            </a-row>
            <a-row :guttter="30">
            </a-row>
            <a-row :guttter="30">
              <a-col :md="24" :xs="24">
                <a-form-item
                  ref="description"
                  label="Descripción del evento"
                  name="description"
                  class="form-font"
                >
                  <a-textarea placeholder="" :rows="4" v-model:value="generalForm.description" class="input-field" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </div>
  </a-row>
</template>
<script>
import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';
import { reactive, ref } from 'vue';
import locale from 'ant-design-vue/es/date-picker/locale/es_ES';
import { generateUUID } from '@/utility/utility';
import * as questionTypes from '@/constants/QuestionTypes.js';
import moment from 'moment';

const GeneralInformation = {
  name: 'GeneralInformation',
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
  },
  props: {
    generalForm: Object,
    sendForm: null,
    eventForm: Object,
    isEdit: Boolean,
  },
  watch: {
    sendForm: function () {
      this.submit();
    },
  },
  emits: ['getFormValues', 'getTitle'],
  async setup(props, {emit}) {



    const formRef = ref();
    const formState = reactive({
      id: props.index,
      layout: 'vertical',
    });

    const rowID = `R${generateUUID()}`
    const colID = `C${generateUUID()}`

    const rules = {
      title: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      grouped_events: [
        {
          required: props.generalForm.type == 'normal' ? true : false,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'number',
        },
      ],
      type: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      event_date: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      event_hour: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      end_date: [
        {
          required: props.generalForm.type == 'normal' ? true : false,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      end_time: [
        {
          required: props.generalForm.type == 'normal' ? true : false,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      start_register_date: [
        {
          required: props.generalForm.type == 'other' ? false : true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      end_register_date: [
        {
          required:  props.generalForm.type == 'other' ? false : true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],

      description: [
        {
          required: false,
          trigger: 'blur',
          type: 'string',
        },
      ],
    };

    const submit = () => {
      formRef.value
        .validate()
        .then(() => {
          emit('getFormValues', { is_ok: true, typeForm: 'generalInfo' });
        })
        .catch((error) => {
          console.log("errororo", error)
          emit('getFormValues', { is_ok: false, typeForm: 'generalInfo' });
        });
    };

    const onChange = (date, dateString) => {

      props.generalForm.start_register_date = dateString[0];
      props.generalForm.end_register_date = dateString[1];
    }

    const disabledDate = (current) => {
      const endDate = props.generalForm.event_date
      const startDate = props.generalForm.registration_date
      if (!current || !endDate) {
        return false
      }
      return current < moment(startDate, 'DD-MM-YYYY') ||
        current.valueOf() > (Date.parse(endDate) + 86400000)
    };

    const sendTitle = (event) => {
      emit('getTitle', event.target.value)
    }

    const handleChangeTypeEvent = (value) => {
      props.generalForm.type = value;
      switch(value){
        case "benefits":
          clearFieldsByTpeEventBenefits();
          break;
        case "other":
          clearFieldsByTypeEventOthers();
          break;
      }
    }

    const clearFieldsByTpeEventBenefits = () => {
      //¿a quien va dirigido?
      props.eventForm.admin_can_register = false;
      props.eventForm.edu_can_register = false;
      props.eventForm.student_can_register = false;
      props.eventForm.citizen_can_register = true;
      props.eventForm.administrative_area_ids = [];
      props.eventForm.work_center_ids = [];

      //Responsable de evento, ¿Solo el Director de CT puede registrar?
      props.eventForm.registration.director_register = 1;
      props.eventForm.registration.responsible_id = '';
      props.eventForm.registration.info_visible = 1;
      props.eventForm.registration.email = '';
      props.eventForm.registration.telephone = '';
      props.eventForm.registration.assistants = false;

      //¿El evento tendrá actividades? , Formulario dinamico
      props.eventForm.activities = false;
      props.eventForm.dynamicForm = getDynamicFormClear();
    }

    const clearFieldsByTypeEventOthers = () => {
      //fecha de inicio y fecha de registro
      props.eventForm.generalInfo.start_register_date = '';
      props.eventForm.generalInfo.end_register_date = '';

      //sedes, ¿Habilitar Asistentes?
      props.eventForm.sedes = [];

      //adjuntar uno o mas archivos
      props.eventForm.files = [];

      //¿A quien va dirigido el evento?
      props.eventForm.admin_can_register = false;
      props.eventForm.edu_can_register = false;
      props.eventForm.student_can_register = false;
      props.eventForm.citizen_can_register = true;
      props.eventForm.administrative_area_ids = [];
      props.eventForm.work_center_ids = [];

      //Responsable de evento, ¿Solo el Director de CT puede registrar?
      props.eventForm.registration.director_register = 1;
      props.eventForm.registration.responsible_id = '';
      props.eventForm.registration.info_visible = 1;
      props.eventForm.registration.email = '';
      props.eventForm.registration.telephone = '';
      props.eventForm.registration.assistants = false;

      //¿El evento tendrá actividades? , Formulario dinamico
      props.eventForm.activities = false;
      props.eventForm.dynamicForm = getDynamicFormClear();
    }

    const getDynamicFormClear = () => {
      return {
        updatedQuestions: [],
        sections: [
          {
            id: `S${generateUUID()}`,
            title: '',
            description: '',
            questions: [
              {
                id: `Q${generateUUID()}`,
                uuid: '',
                validations: {
                  required: false,
                  dataType: 'string',
                  characterType: 'text',
                  validationType: 'limits',
                },
                maxFileSize: 10,
                maxFiles: 1,
                hasDescription: false,
                hasValidations: true,
                title: '',
                description: '',
                type: questionTypes.shortAnswer,
                options: [
                  { id: `O${generateUUID()}`, content: '', label: '' },
                ],
                matrixOptions: {
                  rows: [
                    { id: rowID, content: '' },
                  ],
                  cols: [
                    { id: colID, content: '' },
                  ],
                },
                matrixValidations: {
                  [`T_${colID}_${rowID}`] :{
                    dataType: 'text',
                    validationType: 'limits',
                    min: '',
                    max: '',
                    format: 'email',
                  }
                },
                layout: 'vertical',

              },
            ],
          },

        ],
        layout: 'vertical'
      }
    }
    const dateRangePickerDefaultValue =
      props.isEdit && props.generalForm.start_register_date && props.generalForm.end_register_date
        ? [moment( props.generalForm.start_register_date), moment( props.generalForm.end_register_date)]
        : []

    const event_hour_default = props.generalForm.event_hour ? moment(props.generalForm.event_hour, 'HH:mm:ss') : null;
    const end_time_default = props.generalForm.end_time ? moment(props.generalForm.end_time, 'HH:mm:ss') : null;
    const date = new Date();
    const today =
      date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' +date.getDate();

    return {
      formRef,
      formState,
      rules,
      submit,
      onChange,
      disabledDate,
      locale,
      handleChangeTypeEvent,
      sendTitle,
      moment,
      dateRangePickerDefaultValue,
      event_hour_default,
      end_time_default,
      today
    };
  },
};

export default GeneralInformation;
</script>
<style lang="sass">
.card-top
  background-color: #fff !important
  border-radius: 15px 15px 0 0
  padding: 1rem

.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.input-field
  border-radius: 30px
.ant-calendar-picker-input
  border-radius: 30px !important
.ant-time-picker-input
  border-radius: 30px !important
@media (max-width: 480px)
  .ant-calendar-range
    width: 320px
  .ant-calendar-range-part
    width: 100%
.event-text
  font-size: 14px
</style>
